// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// `ng build --configuration=staging` replaces `environment.ts` with `environment.staging.ts`

export const environment = {
  production: false,
  endpoint: 'https://api.ingeniousplex.com',
  domain: window.location.host.indexOf('localhost') == -1 ? window.location.host : 'console.ingeniousplex.com',
  //domain: window.location.host.indexOf('localhost') == -1 ? window.location.host : 'agencybeta.filmgrapevine.com',
  urlNewConv : '[domain]',
  urlconv: '[domain]',
  //postbackUrlInstallCustom: `/postback?click_id={click_id}&event=postnback&saleAmount={sale_amount}&affiliate_id={affiliate_id}&device_id={device_id}&google_aid={google_aid}&campaign_id={campaign_id}&media_channel={media_channel}&mac_address={mac_address}&offer_id={offer_id}&publisher_id={publisher_id}&creative_id={aff_sub2}&source={aff_sub3}&user_agent={user_agent}&data={aff_sub4}&site_id={site_id}&ip={ip}&app_id={app_id}&isp={isp}&domain={domain}&id=151249868264158254254026`,
  //postbackUrlInstall:`/postback?click_id={click_id}&event=postnback&saleAmount={sale_amount}&affiliate_id={affiliate_id}&device_id={device_id}&google_aid={google_aid}&campaign_id={campaign_id}&media_channel={media_channel}&mac_address={mac_address}&offer_id={offer_id}&publisher_id={publisher_id}&creative_id={aff_sub2}&source={aff_sub3}&user_agent={user_agent}&data={aff_sub4}&site_id={site_id}&ip={ip}&app_id={app_id}&isp={isp}&domain={domain}&id=151249868264158254254026`,
  // postbackUrlInstall: `/empty.gif?click_id={click_id}&event=postnback&saleAmount={sale_amount}&affiliate_id={affiliate_id}&device_id={device_id}&google_aid={google_aid}&campaign_id={campaign_id}&mac_address={mac_address}&offer_id={offer_id}&publisher_id={publisher_id}&site_id={site_id}&app_id={app_id}&id=151249868264&user_agent={user_agent}&ip={ip}`,
  postbackUrlCustom1 : "/postback?event=",
  postbackUrlCustom2 : `&saleAmount={sale_amount}&affiliate_id={affiliate_id}&device_id={device_id}&google_aid={google_aid}&mac_address={mac_address}&offer_id={offer_id}&publisher_id={publisher_id}&site_id={site_id}&app_id={app_id} &click_id={aff_click_id}&creative_id={aff_sub2}&source={aff_sub3}&data={aff_sub4}&user_agent={user_agent}&ip={ip}&isp={isp}&domain={domain}`,
  ownDomains: ['console.vertoz.com', 'consolebeta.vertoz.com', 'localhost:3333','v2console.vertoz.com','dsp.vertoz.com'],
  stripeEndpoint : 'https://cors-anywhere.herokuapp.com/bireport.vertoz.com:4242'
  // urlNewConv: 'http://beta-cookie.vrtzads.com',
  // urlconv: 'http://beta-postback.vrtzads.com',
  // postbackUrlInstall: `/PostbackTracking/s2s/vrtzads?click_id:{click_id}&event=install&saleAmount={sale_amount}
  // &affiliate_id={affiliate_id}&device_id={device_id}&google_aid={google_aid}&campaign_id={campaign_id}
  // &mac_address={mac_address}&offer_id={offer_id}&publisher_id={publisher_id}&site_id={site_id}&app_id={app_id}&id=`,
  // postbackUrlCustom1: "/PostbackTracking/s2s/vrtzads?click_id={click_id}&event=",
  // postbackUrlCustom2: `&saleAmount={sale_amount}&affiliate_id={affiliate_id}&device_id={device_id}
  // &google_aid={google_aid}&campaign_id={campaign_id}&mac_address={mac_address}&offer_id={offer_id}
  // &publisher_id={publisher_id}&site_id={site_id}&app_id={app_id}`,
  // ownDomains: ['console.vertoz.com', 'consolebeta.vertoz.com', 'localhost:3333','v2console.vertoz.com']
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
